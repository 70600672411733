import React, { FC, useEffect, useState } from 'react'
import { FormBlock } from '../../admin-page/admin-page.styles'
import { SERContainer } from './index.styles'
import { Text } from '../../text.component'
import { TrendDown, TrendUp } from '../../../assets/icons'
import { SER1, SER10, SER11, SER12, SER13, SER14, SER16, SER17, SER18, SER19, SER2, SER20, SER21, SER22, SER23, SER24, SER25, SER26, SER27, SER28, SER29, SER3, SER30, SER4, SER5, SER6, SER7 } from '../../../assets/icons/ser'
import { isRegion, OBLAST_KATO, t } from '../../../utils/helpers.utils'

interface IProps {
  DATA: any;
  kato: any;
}

const SERIcons: any = {
  'INDUSTRIAL_PRODUCTION': <SER1 />,
  'MINING_INDUSTRY': <SER2 />,
  'MANUFACTURING_INDUSTRY': <SER3 />,
  'AGRICULTURE': <SER4 />,
  'INVESTMENT': <SER5 />,
  'HOUSE_COMMISSIONING': <SER6 />,
  'CONSTRUCTION': <SER7 />,
  'TRANSPORT_WAREHOUSING': <SER29 />,
  'CONNECTION': <SER30 />,
  'INCOME_RECEIPT': <SER10 />,
  'BUDGET_EXPENDITURE': <SER14 />,
  'CURRENT_MSP': <SER11 />,
  'RETAIL': <SER12 />,
  'WHOLESALE': <SER13 />,
  'EMPLOYED': <SER16 />,
  'UNEMPLOYED': <SER17 />,
  'NEW_WORKPLACE': <SER18 />,
  'AVERAGE_MONTHLY_SALARY': <SER19 />,
  'KRC': <SER20 />,
  'SHEEP': <SER21 />,
  'GOAT': <SER22 />,
  'HORSE': <SER23 />,
  'CAMEL': <SER24 />,
  'BIRD': <SER25 />,
  'MEAT': <SER26 />,
  'COW_MILK': <SER27 />,
  'CHICKEN_EGG': <SER28 />,
}

const keyToKey: any = {
  'Инвестиции в основной капитал': 'INVESTMENT',
  'Объем строительных работ': 'CONSTRUCTION',
  'Промышленность': 'INDUSTRIAL_PRODUCTION',
  'Сельское хозяйство': 'AGRICULTURE',
  'Горнодобывающая промышленность': 'MINING_INDUSTRY',
  'Обрабатывающая промышленность': 'MANUFACTURING_INDUSTRY',
  'Ввод жилья': 'HOUSE_COMMISSIONING',
  'Транспорт и складирование': 'TRANSPORT_WAREHOUSING',
  'Связь': 'CONNECTION',
}

const order = [
  'INVESTMENT',
  'INDUSTRIAL_PRODUCTION',
  'MINING_INDUSTRY',
  'MANUFACTURING_INDUSTRY',
  'CONSTRUCTION',
  'AGRICULTURE',
  'TRANSPORT_WAREHOUSING',
  'HOUSE_COMMISSIONING',
]

const keyToKeyInverted = Object.keys(keyToKey).reduce((acc: any, key: any) => {
  acc[keyToKey[key]] = key
  return acc
}, {})

const SER: FC<IProps> = ({ DATA, kato }) => {

  const [data, setData] = useState<any>(null)

  useEffect(() => {
    if (DATA) {
      const SER = order.map((key: string) => {
        const item = DATA.SER?.find((item: any) => item.subType === key)
        if (item) {
          return {
            ...item,
            value: item.currentYearVal.toLocaleString() || '-',
            increase: item.increaseVal || '-',
            place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
            icon: SERIcons[key] || null,
            name: keyToKeyInverted[key],
            add: key === 'HOUSE_COMMISSIONING' ? 'кв. м.' : '',
            ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
            ifo: item.ifo.toString() || '-',
          }
        }
      })

      const BUDGET = DATA.BUDGET?.map((item: any) => ({
        ...item,
        value: item.currentYearVal.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        development: item.additionalInfo?.development || '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo.toString() || '-',
      }))

      const MCB = DATA.MCB?.map((item: any) => ({
        ...item,
        value: item.currentYearVal.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        add: item.subType === 'CURRENT_MSP' ? 'ед.' : '',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo.toString() || '-',
      }))

      const EMPLOYMENT = DATA.EMPLOYMENT?.map((item: any) => ({
        ...item,
        value: item.currentYearVal.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        add: item.subType === 'AVERAGE_MONTHLY_SALARY'
          ? 'тенге'
          : item.subType === 'NEW_WORKPLACE'
            ? 'ед.'
            : 'чел.',
        increaseAdd: item.subType === 'UNEMPLOYED' ? 'чел.' : null,
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo.toString() || '-',
      }))

      const LIVESTOCK = DATA.LIVESTOCK?.map((item: any) => ({
        ...item,
        value: item.currentYearVal.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        add: ['MEAT', 'COW_MILK'].includes(item.subType)
          ? 'тонн'
          : item.subType === 'CHICKEN_EGG'
            ? 'тыс. шт.'
            : '',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo.toString() || '-',
      }))

      setData({ SER, BUDGET, MCB, EMPLOYMENT, LIVESTOCK })
    }

  }, [DATA])

  if (!data) return null

  return (
    data && <SERContainer>
      <FormBlock type='white' style={{ border: 0 }}>
        <div className='header'>
          <div className="dfc">
            <div className="left" style={{ background: 'none' }}></div>
            <div className="right">
              <div className="budget-data__item header">
                <div className="name">
                  <svg width={'1.25rem'} height={'1.25rem'}></svg>
                  <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Показатель</Text>
                </div>
                <div className="rest">
                  <div style={{ minWidth: '11rem' }}>
                    <Text
                      fontSize='1.125rem'
                      color="var(--primary-color)"
                      fontWeight={500}

                    >
                      ИФО
                    </Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500}>%</Text>
                  </div>
                  <Text
                    fontSize='1.125rem'
                    color="var(--primary-color)"
                    fontWeight={500}
                    style={{ minWidth: '11rem' }}
                  >
                    Изменение за год
                  </Text>
                  <div style={{ minWidth: '8.125rem' }}>
                    <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Значение</Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase' }}>{+kato === OBLAST_KATO ? 'млрд тенге' : 'млн тенге'}</Text>
                  </div>
                  <div style={{ minWidth: '11.25rem' }}>
                    <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>{+kato === OBLAST_KATO ? 'Место среди регионов' : 'Место среди районов'}</Text>
                    <div className='doubled'>
                      <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>ИФО, %</Text>
                      <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>Объем, млрд тг</Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white' >
        <div className="dfc ">
          <div className="left">
            <Text fontSize='1.0625rem' color="#fff" fontWeight={700}>ПОКАЗАТЕЛИ СЭР</Text>
          </div>
          <div className="right">
            {data.SER?.map((item: any) => (
              <div className='budget-data__item'>
                <div className='name'>
                  {item.icon}
                  <Text fontSize='0.9375rem' color="var(--shuttleGray)" fontWeight={700}>{item.name}</Text>
                </div>
                <div className='rest'>
                  <Text
                    fontSize='0.9375rem'
                    color="var(--shuttleGray)"
                    fontWeight={400}
                    style={{ minWidth: '11rem' }}>{item.ifo || '-'}
                  </Text>

                  <Text
                    fontSize='0.75rem'
                    color={item.increase > 0 ? "var(--success-green)" : "var(--failure-red)"}
                    fontWeight={500}
                    style={{ minWidth: '11rem' }}>
                    {item.increase > 0 ? <TrendUp width={10} height={8} /> : <TrendDown width={10} height={8} />}
                    {' '}{item.increase}%
                  </Text>

                  <Text
                    fontSize='0.9375rem'
                    color="var(--shuttleGray)"
                    fontWeight={400}
                    style={{ minWidth: '8.125rem' }}>{item.value || '-'} {item.add}
                  </Text>

                  <div className='doubled'>
                    <Text
                      fontSize='0.9375rem'
                      color="var(--shuttleGray)"
                      fontWeight={400}
                      style={{ minWidth: '4.5rem' }}>{item.ifoPlace || '-'} {(+kato !== OBLAST_KATO && isRegion(kato)) ? '/ 13' : '/ 20'}</Text>
                    <Text
                      fontSize='0.9375rem'
                      color="var(--shuttleGray)"
                      fontWeight={400}
                      style={{ minWidth: '4.5rem' }}>{+item.place || '-'} {(+kato !== OBLAST_KATO && isRegion(kato)) ? '/ 13' : '/ 20'}</Text>
                  </div>

                </div>
              </div>
            ))}
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white' style={{ border: 0 }}>
        <div className='header'>
          <div className="dfc">
            <div className="left" style={{ background: 'none' }}></div>
            <div className="right">
              <div className="budget-data__item header">
                <div className="name">
                  <svg width={'1.25rem'} height={'1.25rem'}></svg>
                  <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Показатель</Text>
                </div>
                <div className="rest">
                  {/* <div style={{ minWidth: '11rem' }}>
                    <Text
                      fontSize='1.125rem'
                      color="var(--primary-color)"
                      fontWeight={500}

                    >ИФО</Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500}>%</Text>
                  </div> */}
                  <div style={{ minWidth: '8.125rem' }}>
                    <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Значение</Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase' }}>{+kato === OBLAST_KATO ? 'млрд тенге' : 'млн тенге'}</Text>
                  </div>
                  <Text
                    fontSize='1.125rem'
                    color="var(--primary-color)"
                    fontWeight={500}
                    style={{ minWidth: '11rem' }}
                  >Исполнение за плановый период</Text>
                  <Text
                    fontSize='1.125rem'
                    color="var(--primary-color)"
                    fontWeight={500}
                    style={{ minWidth: '11rem' }}
                  >Исполнение за год</Text>
                  <div style={{ minWidth: '11.25rem' }}>
                    {+kato !== OBLAST_KATO && isRegion(kato) && <>
                      <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Место среди районов</Text>
                      <div className='doubled'>
                        <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>ИФО, %</Text>
                        <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>Объем, млрд тг</Text>
                      </div>
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white'>
        <div className="dfc">
          <div className="left">
            <Text fontSize='1.0625rem' color="#fff" fontWeight={700}>БЮДЖЕТ</Text>
          </div>
          <div className="right">
            {data.BUDGET?.map((item: any, index: any) => (
              <div className='budget-data__item'>
                <div className='name'>
                  {item.icon}
                  <Text fontSize='0.9375rem' color="var(--shuttleGray)" fontWeight={700}>{item.name}</Text>
                </div>
                <div className='rest'>
                  {/* <Text
                    fontSize='0.9375rem'
                    color="var(--shuttleGray)"
                    fontWeight={400}
                    style={{ minWidth: '11rem' }}>{item.ifo || '-'}</Text> */}
                  <Text
                    fontSize='0.9375rem'
                    color="var(--shuttleGray)"
                    fontWeight={400}
                    style={{ minWidth: '8.125rem' }}>{item.value}</Text>

                  <Text
                    fontSize='0.9375rem'
                    color="var(--shuttleGray)"
                    fontWeight={400}
                    style={{ minWidth: '11rem' }}>{item.increase}</Text>
                  <Text
                    fontSize='0.9375rem'
                    color="var(--shuttleGray)"
                    fontWeight={400}
                    style={{ minWidth: '11rem' }}>{item.development || '-'}%</Text>
                  <div className='doubled'>
                    {
                      +kato !== OBLAST_KATO && isRegion(kato) && <>
                        <Text
                          fontSize='0.9375rem'
                          color="var(--shuttleGray)"
                          fontWeight={400}
                          style={{ minWidth: '4.5rem' }}>{(+kato !== OBLAST_KATO && isRegion(kato)) && `${item.ifoPlace || '-'} / 13`}  </Text>
                        <Text
                          fontSize='0.9375rem'
                          color="var(--shuttleGray)"
                          fontWeight={400}
                          style={{ minWidth: '4.5rem' }}>{(+kato !== OBLAST_KATO && isRegion(kato)) && `${+item.place || '-'} / 13`}  </Text>
                      </>
                    }
                  </div>
                </div>

              </div>
            ))}
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white' style={{ border: 0 }}>
        <div className='header'>
          <div className="dfc">
            <div className="left" style={{ background: 'none' }}></div>
            <div className="right">
              <div className="budget-data__item header">
                <div className="name">
                  <svg width={'1.25rem'} height={'1.25rem'}></svg>
                  <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Показатель</Text>
                </div>
                <div className="rest">
                  <div style={{ minWidth: '11rem' }}>
                    <Text
                      fontSize='1.125rem'
                      color="var(--primary-color)"
                      fontWeight={500}

                    >ИФО</Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500}>%</Text>
                  </div>

                  <Text
                    fontSize='1.125rem'
                    color="var(--primary-color)"
                    fontWeight={500}
                    style={{ minWidth: '11rem' }}
                  >
                    Изменение за год
                  </Text>

                  <div style={{ minWidth: '8.125rem' }}>
                    <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Значение</Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase' }}>{+kato === OBLAST_KATO ? 'млрд тенге' : 'млн тенге'}</Text>
                  </div>

                  <div style={{ minWidth: '11.25rem' }}>
                    {+kato !== OBLAST_KATO && isRegion(kato) && <>
                      <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Место среди районов</Text>
                      <div className='doubled'>
                        <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>ИФО, %</Text>
                        <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>Объем, млрд тг</Text>
                      </div>
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white'>
        <div className="dfc">
          <div className="left">
            <Text fontSize='1.0625rem' color="#fff" fontWeight={700}>МСБ</Text>
          </div>
          <div className="right">
            {
              data.MCB?.map((item: any, index: any) => (
                <div className='budget-data__item'>
                  <div className='name'>
                    {item.icon}
                    <Text fontSize='0.9375rem' color="var(--shuttleGray)" fontWeight={700}>{item.name}</Text>
                  </div>
                  <div className="rest">
                    <Text
                      fontSize='0.9375rem'
                      color="var(--shuttleGray)"
                      fontWeight={400}
                      style={{ minWidth: '11rem' }}>{item.ifo || '-'}</Text>

                    <Text
                      fontSize='0.75rem'
                      color={item.increase > 0 ? "var(--success-green)" : "var(--failure-red)"}
                      fontWeight={500}
                      style={{ minWidth: '11rem' }}>
                      {item.increase > 0 ? <TrendUp width={10} height={8} /> : <TrendDown width={10} height={8} />}
                      {' '}{item.increase}%
                    </Text>
                    <Text
                      fontSize='0.9375rem'
                      color="var(--shuttleGray)"
                      fontWeight={400}
                      style={{ minWidth: '8.125rem' }}>{item.value} {item.add}
                    </Text>

                    <div className='doubled'>
                      {
                        +kato !== OBLAST_KATO && isRegion(kato) && <>
                          <Text
                            fontSize='0.9375rem'
                            color="var(--shuttleGray)"
                            fontWeight={400}
                            style={{ minWidth: '4.5rem' }}>{(+kato !== OBLAST_KATO && isRegion(kato)) && `${item.ifoPlace || '-'} / 13`}  </Text>
                          <Text
                            fontSize='0.9375rem'
                            color="var(--shuttleGray)"
                            fontWeight={400}
                            style={{ minWidth: '4.5rem' }}>{(+kato !== OBLAST_KATO && isRegion(kato)) && `${+item.place || '-'} / 13`}  </Text>
                        </>
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white' style={{ border: 0 }}>
        <div className='header'>
          <div className="dfc">
            <div className="left" style={{ background: 'none' }}></div>
            <div className="right">
              <div className="budget-data__item header">
                <div className="name">
                  <svg width={'1.25rem'} height={'1.25rem'}></svg>
                  <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Показатель</Text>
                </div>
                <div className="rest">
                  <div style={{ minWidth: '11rem' }}>
                    <Text
                      fontSize='1.125rem'
                      color="var(--primary-color)"
                      fontWeight={500}

                    >ИФО</Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500}>%</Text>
                  </div>

                  <Text
                    fontSize='1.125rem'
                    color="var(--primary-color)"
                    fontWeight={500}
                    style={{ minWidth: '11rem' }}
                  >
                    Изменение за год
                  </Text>

                  <div style={{ minWidth: '8.125rem' }}>
                    <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Значение</Text>
                  </div>
                  <div style={{ minWidth: '11.25rem' }}>
                    {+kato !== OBLAST_KATO && isRegion(kato) && <>
                      <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Место среди районов</Text>
                      <div className='doubled'>
                        <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>ИФО, %</Text>
                        <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>Объем, млрд тг</Text>
                      </div>
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white'>
        <div className="dfc">
          <div className="left">
            <Text fontSize='1.0625rem' color="#fff" fontWeight={700}>ЗАНЯТОСТЬ
              НАСЕЛЕНИЯ</Text>
          </div>
          <div className="right">
            {
              data.EMPLOYMENT?.map((item: any, index: any) => (
                <div className='budget-data__item'>
                  <div className='name'>
                    {item.icon}
                    <Text fontSize='0.9375rem' color="var(--shuttleGray)" fontWeight={700}>{item.name}</Text>
                  </div>
                  <div className="rest">
                    <Text
                      fontSize='0.9375rem'
                      color="var(--shuttleGray)"
                      fontWeight={400}
                      style={{ minWidth: '11rem' }}>{item.ifo || '-'}
                    </Text>

                    <Text
                      fontSize='0.75rem'
                      color={item.increase > 0 ? "var(--success-green)" : "var(--failure-red)"}
                      fontWeight={500}
                      style={{ minWidth: '11rem' }}>
                      {item.increase > 0 ? <TrendUp width={10} height={8} /> : <TrendDown width={10} height={8} />}
                      {' '} {item.increase} {item.increaseAdd || '%'}
                    </Text>

                    <Text
                      fontSize='0.9375rem'
                      color="var(--shuttleGray)"
                      fontWeight={400}
                      style={{ minWidth: '8.125rem', maxWidth: "15rem" }}>
                      {item.value} {item.add}
                    </Text>
                    <div className='doubled'>
                      {
                        +kato !== OBLAST_KATO && isRegion(kato) && <>
                          <Text
                            fontSize='0.9375rem'
                            color="var(--shuttleGray)"
                            fontWeight={400}
                            style={{ minWidth: '4.5rem' }}>{(+kato !== OBLAST_KATO && isRegion(kato)) && `${item.ifoPlace || '-'} / 13`}  </Text>
                          <Text
                            fontSize='0.9375rem'
                            color="var(--shuttleGray)"
                            fontWeight={400}
                            style={{ minWidth: '4.5rem' }}>{(+kato !== OBLAST_KATO && isRegion(kato)) && `${+item.place || '-'} / 13`}  </Text>
                        </>
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white' style={{ border: 0 }}>
        <div className='header'>
          <div className="dfc">
            <div className="left" style={{ background: 'none' }}></div>
            <div className="right">
              <div className="budget-data__item header">
                <div className="name">
                  <svg width={'1.25rem'} height={'1.25rem'}></svg>
                  <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Показатель</Text>
                </div>
                <div className="rest">
                  <div style={{ minWidth: '11rem' }}>
                    <Text
                      fontSize='1.125rem'
                      color="var(--primary-color)"
                      fontWeight={500}

                    >ИФО</Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500}>%</Text>
                  </div>
                  <Text
                    fontSize='1.125rem'
                    color="var(--primary-color)"
                    fontWeight={500}
                    style={{ minWidth: '11rem' }}
                  >Изменение за год
                  </Text>

                  <div style={{ minWidth: '8.125rem' }}>
                    <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Значение</Text>
                    <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase' }}>голов</Text>
                  </div>

                  <div style={{ minWidth: '11.25rem' }}>
                    {+kato !== OBLAST_KATO && isRegion(kato) && <>
                      <Text fontSize='1.125rem' color="var(--primary-color)" fontWeight={500}>Место среди районов</Text>
                      <div className='doubled'>
                        <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>ИФО, %</Text>
                        <Text fontSize='0.75rem' color="var(--gray)" fontWeight={500} style={{ textTransform: 'lowercase', width: '4.5rem' }}>Объем, млрд тг</Text>
                      </div>
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormBlock>

      <FormBlock type='white'>
        <div className="dfc">
          <div className="left">
            <Text fontSize='1.0625rem' color="#fff" fontWeight={700}>ЖИВОДНО ВОДСТВО</Text>
          </div>
          <div className="right">
            {
              data.LIVESTOCK?.map((item: any, index: any) => (
                <div className='budget-data__item'>
                  <div className='name'>
                    {item.icon}
                    <Text fontSize='0.9375rem' color="var(--shuttleGray)" fontWeight={700}>{item.name}</Text>
                  </div>
                  <div className="rest">
                    <Text
                      fontSize='0.9375rem'
                      color="var(--shuttleGray)"
                      fontWeight={400}
                      style={{ minWidth: '11rem' }}>{item.ifo || '-'}</Text>
                   
                    <Text
                      fontSize='0.75rem'
                      color={item.increase > 0 ? "var(--success-green)" : "var(--failure-red)"}
                      fontWeight={500}
                      style={{ minWidth: '11rem' }}>
                      {item.increase > 0 ? <TrendUp width={10} height={8} /> : <TrendDown width={10} height={8} />}
                      {' '} {item.increase}%
                    </Text>

                    <Text
                      fontSize='0.9375rem'
                      color="var(--shuttleGray)"
                      fontWeight={400}
                      style={{ minWidth: '8.125rem', maxWidth: "15rem" }}>{item.value} {item.add}
                    </Text>
                    
                    <div className='doubled'>
                      {
                        +kato !== OBLAST_KATO && isRegion(kato) && <>
                          <Text
                            fontSize='0.9375rem'
                            color="var(--shuttleGray)"
                            fontWeight={400}
                            style={{ minWidth: '4.5rem' }}>{(+kato !== OBLAST_KATO && isRegion(kato)) && `${item.ifoPlace || '-'} / 13`}  </Text>
                          <Text
                            fontSize='0.9375rem'
                            color="var(--shuttleGray)"
                            fontWeight={400}
                            style={{ minWidth: '4.5rem' }}>{(+kato !== OBLAST_KATO && isRegion(kato)) && `${+item.place || '-'} / 13`}  </Text>
                        </>
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </FormBlock>
    </SERContainer >
  )
}

export default SER